* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px;
  color: #fff;
  position: fixed;
  width: 100%;
  transition: all 0.5s;
}


.header-scrolled {
  background-color: #292F45;
  opacity: 0.9;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
}

.links {
  display: flex;
  column-gap: 40px;
  padding-right: 50px;
}