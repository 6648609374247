.footer {
    background-color: #292F45;
    position: fixed;
    width: 100%;
    bottom: 0;
    color: #fff;
    opacity: 0.95;
    font-size: 0.9rem;
    height: 50px;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    z-index: 2;
    font-family: 'Jost';
}