.home-grid-container {
  grid-template-areas:
    'header'
    'header-image'
    'home-title'
    'vl'
    'home-container'
    'home-read'
    'home-services'
    'home-testimonial'
    'home-contact'
    'footer';
  gap: 10px;
}

.home-header {
  grid-area: header;
  height: 60px;
}

.home-header-image {
  grid-area: header-image;
}

.home-title {
  grid-area: home-title;
  background-color: #D3D3D3;
  height: 100px;
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  margin-top: -10px;
  text-align: center;
}

.home-trivine {
  animation: mymove 3s;
  text-align: center;
}

.home-services {
  grid-area: home-services;
}

.App-logo {
  width: 100%;
  height: 400px;
  object-fit: cover;
  z-index: -1;
  pointer-events: none;
}

.intro {
  background-color: transparent;
  text-align: center;
  font-size: 36px;
}

.intro-message {
  background-color: transparent;
  text-align: center;
  font-size: 24px;
  width: 100%
}

.grid-container {
  display: flex;
  flex-wrap: wrap;
  margin-right: auto;
  margin-left: auto;
}

.grid-item {
  flex: 1
}

.grid-item1 {
  margin: auto;
  text-align: center;
}

.white {
  color: white;
}

.count {
  font-size: 48px !important;
}

.text {
  font-size: 18px !important;
  padding-top: 10px;
}

.grid-item-message {
  padding-top: 16px;
}

.side {
  padding: 10px;
}

.side-content-center {
  flex: 1;
  display: flex;
  align-items: center;
  text-align: left;
}

.margin36 {
  margin: 36px;
}

.font40 {
  font-size: 40px;
}

.home-trivine-text {
  padding-top: 16px;
  width: 75%;
  margin: auto!important;
  text-align: center;
}

.vl {
  border-right: 1px solid rgb(234, 111, 102);
  height: 100px;
  width: 50%;
  margin-top: -24px;
  z-index: 1;
  grid-area: vl;
}

.home-benefits {
  display: flex;
  justify-content: center;
  margin: 36px;
  animation: mymove 3s;
}

.card-section {
  max-width: 345;
  width: 345px;
  margin: 20px;
}

.home-read {
  grid-area: home-container;
  display: flex;
  width: 300px;
  background-color: #fff;
  box-shadow: var(--shd, 0 1px 4px rgba(0, 0, 0, .6));
  width: 80%;
  margin: auto;
  margin-top: -24px;
  justify-content: center;
  justify-items: center;
  align-items: center;
  padding: 20px;
  text-align: center;
  flex-wrap: wrap;
  animation: mymove 3s;
}

.home-experience-desc {
  background-color: rgb(211, 211, 211);
  height: 400px;
  padding: 64px 24px 48px;
}

.padding12 {
  padding-top: 12px;
}

.padding24 {
  padding: 24px 0;
}

.orangeColor {
  color: rgb(234, 111, 102);
}

.home-experience {
  background-color: rgb(234, 111, 102);
  height: 400px;
  display: grid;
  grid-template-columns: auto auto;
}

.home-testimonial {
  grid-area: home-testimonial;
  height: 400px;
  width: 100%;
  background-color: rgb(41, 47, 69);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-testimonial-text {
  margin: auto;
  color: #fff;
  width: 75%;
}

.home-companies {
  display: flex;
  justify-content: space-around;
}

.home-company {
  animation: linear infinite;
  background-color: white;
  animation-name: run;
  animation-direction: alternate-reverse;
  animation-duration: 5s;
  width: 180px;
  height: 180px;
}

.home-contact {
  grid-area: home-contact;
  height: 400px;
  background-color: #d3d3d3;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-contact-work {
  width: 150px;
  height: 150px;
  margin-bottom: 24px;
}

.home-contact-work-text {
  color: black;
  margin-bottom: 24px;
  width: 75%;
}

.readMoreBtn {
  background-color: rgb(234, 111, 102);
  margin-top: 16px;
  padding: 8px;
  width: 150px;
  color: black;
  border: none;
  font-family: Jost;
}

.readMoreBtn:hover {
  background-color: #292F45;
  color: white;
}

@keyframes mymove {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0%);
  }
}

@keyframes slidein {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0%);
  }
}

@media screen and (max-width: 768px) {
  .home-companies {
    display: grid;
  }

  .grid-container {
    display: grid;
  }

  .home-benefits {
    display: grid;
  }
}

@keyframes run {
  0% {
    left: 10%;
    transform: translateX(10%);
  }

  100% {
    left: 20%;
    transform: translateX(20%);
  }
}