* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.service-grid-container {
  grid-template-areas:
    'header'
    'intro'
    'feature-title'
    'feature'
    'end-feature'
    'footer';
  gap: 10px;
}


.services-grid-item {
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  margin: 20px;
  text-align: center;
  animation: mymove 5s;
}

.services-grid-item:hover {
  background-color: rgb(234, 111, 102, 0.9);
  color: #fff
}

.services-grid-item-message {
  padding-top: 16px;
}

.service-item-section {
  margin: 36px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

}

.service-header {
  grid-area: header;
  height: 80px;
}

.service-item1 {
  grid-area: intro;
  display: flex;
  flex-wrap: wrap;
  padding: 20px
}

.service-item2 {
  grid-area: feature-title;
  background-color: #D3D3D3;
  margin-top: 36px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 20px;
  animation: mymove 3s;
}

.service-item3 {
  grid-area: feature;
  margin-top: 36px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 20px;
  animation: mymove 5s;
}

.service-item4 {
  grid-area: end-feature;
  margin-top: 36px;
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  margin-bottom: 36px;
  animation: slidein 5s;
  animation-delay: 1.5s;
  justify-content: center;
  width: 100%;
  background-color: rgb(234, 111, 102);
  border-radius: 15px;
}

.service-footer {
  grid-area: footer;
  padding-top: 80px;
}


@keyframes mymove {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0%);
  }
}

@keyframes slidein {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0%);
  }
}