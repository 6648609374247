* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.trivine-grid-container {
    grid-template-areas:
        'header'
        'main'
        'menu'
        'about'
        'testimonial'
        'slider'
        'footer';
    gap: 10px;
}

.trivine-header {
    grid-area: header;
    animation: mymove 3s;
}

.trivine-item2 {
    grid-area: main;
    display: flex;
    justify-content: center;
    background-color: #D3D3D3;
}

.trivine-item3 {
    grid-area: menu;
    display: flex;
    justify-content: center;
}

.trivine-item4 {
    grid-area: about;
    display: flex;
    justify-content: center;
    animation: mymove 3s;
}

.trivine-item5 {
    grid-area: testimonial;
    display: flex;
    justify-content: center;
    margin-top: 36px;
    margin-bottom: 36px;
    animation: mymove 5s;
}

.trivine-item6 {
    grid-area: slider;
    display: flex;
    justify-content: center;
    margin-top: -180px;
}

.trivine-footer {
    grid-area: footer;
    padding-top: 80px;
}

.trivine-item-comments {
    animation: mymove 5s;
    display: flex;
    justify-content: center;
    background-color: #D3D3D3;
    padding-top: 36px;
    padding-bottom: 36px;
}

.trivine-item {
    height: 200px;
    width: 200%;
    background-color: #D3D3D3;
}

.image-slider {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image {
    width: 80%;
    height: 80%;
    border-radius: 12px;
}

.left {
    position: absolute;
    left: 3rem;
    top: 50%;
    font-size: 1rem;
    color: rgb(234, 111, 102);
    z-index: 5;
    cursor: pointer;
    user-select: none;
}

.right {
    position: absolute;
    right: 3rem;
    top: 50%;
    font-size: 2rem;
    color: rgb(234, 111, 102);
    z-index: 5;
    cursor: pointer;
    user-select: none;
}

.currentSlide {
    transition-duration: 0.5s ease;
    opacity: 0;
    display: flex;
    justify-content: center;
}

.currentSlide.active {
    opacity: 1;
    transition-duration: 0.5s;
    transform: scale(1.2);
    display: flex;
    justify-content: center;
}

h2 {
    text-align: center;
}


@media screen and (max-width: 768px) {
    .trivine-item-comments {
        display: grid;
    }
}

@keyframes mymove {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0%);
    }
}
