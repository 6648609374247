* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.about-app-logo {
    width: 100%;
    height: auto;
    object-fit: contain;
    z-index: -1;
    pointer-events: none;
}

.MuiCardContent-root:last-child {
    background-color: #D3d3d3;
    color: black;
}

.bold {
    font-weight: bold !important;
}

.about-grid-container {
    grid-template-areas:
        'header header'
        'image image'
        'main main'
        'menu'
        'footer footer';
    gap: 10px;
}

.about-header {
    grid-area: header;
}

.about-item1 {
    grid-area: image;
    margin-bottom: -16px;
    animation: mymove 3s;
}

.about-item2 {
    grid-area: main;
    display: flex;
    justify-content: center;
    animation: mymove 3s;
}

.about-item3 {
    grid-area: menu;
    display: flex;
    justify-content: center;
    background-color: #D3D3D3;
    padding-top: 36px;
    padding-bottom: 72px;
    animation: mymove 5s;
}

.about-footer {
    grid-area: footer;
    padding-top: 80px;
}

.about-info-header {
    text-align: center;
}

.about-item {
    width: 90%;
    text-align: center;
    background-color: #D3D3D3;
}

.location-icon {
    padding-top: 8px;
}


@keyframes mymove {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0%);
    }
}